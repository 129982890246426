import styled from 'styled-components'

// config
import { isDgmt } from 'config/localization'

// utils
import th from 'utils/themeHelper'

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['requiredField', 'maxWidth'].includes(prop)
})`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InnerWrapper = styled.div`
  position: relative;
`

export const ComponentError = styled.div`
  font-size: 13px;
  border: 1px solid ${th('text.error')};
  color: ${th('text.error')};
  padding: 8px;
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
  font-family: ${th('fonts.bold')};
  line-height: 1.35em;
`

export const WordLimitWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 6px;
  font-size: 10px;
  line-height: 10px;
  border: 1px solid ${th('borders.default')};
  color: ${props => (props.nearingLimit ? th('text.error') : th('text.light'))};
  padding: 3px;
  border-radius: 3px;

  @media screen and (min-width: 769px) {
    right: 6px;
    top: 8px;
  }
`

export const Input = styled.textarea.withConfig({
  shouldForwardProp: prop =>
    ![
      'hasError',
      'hideArrows',
      'maxWidth',
      'minHeight',
      'requiredField',
      'touched',
      'wordLimit'
    ].includes(prop)
})`
  appearance: none;
  background-color: ${props => (props.disabled ? th('backgrounds.light') : 'white')};
  border-width: ${({ requiredField, hasError }) =>
    hasError ? '2px' : requiredField ? '2px' : '1px'};
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ disabled, requiredField, hasError, theme }) =>
    disabled
      ? theme.borders.medium
      : hasError
      ? theme.borders.error
      : requiredField
      ? theme.borders.required
      : theme.borders.default};
  color: ${th('text.dark')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  font-family: ${th('fonts.light')};
  font-size: 15px;
  line-height: 1.25em;
  min-height: ${props => props.minHeight || '150px'};
  max-width: ${props => props.maxWidth || '100%'};
  outline: none;
  padding: ${props => (props.wordLimit ? '20px 15px' : '15px')};
  resize: none;
  width: 100%;

  &:hover {
    border-color: ${({ disabled, hasError, theme }) =>
      disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.borders.dark};
  }

  &:focus {
    border-color: ${({ disabled, hasError, theme }) => {
      if (isDgmt) {
        return disabled
          ? theme.borders.medium
          : hasError
          ? theme.borders.error
          : theme.borders.medium
      }

      return disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.secondary.base
    }};
  }

  &::placeholder {
    color: ${th('text.light')};
  }

  @media screen and (max-width: 600px) {
    min-height: 50vh;
  }
`
