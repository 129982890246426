import React, { Component } from 'react'
import Select, { createFilter, components } from 'react-select'
import styled from 'styled-components'

// icons
import { PlusSign } from 'components/Icons/Icons'
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'
import { Close } from '@styled-icons/material/Close'

// utils
import th from 'utils/themeHelper'

// config
import { isDgmt } from 'config/localization'

// Load components synchronously
import { Error, Label, RequiredTag, SubLabel, Success } from 'components/InputLabels/InputLabels'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const borderColorBottom = ({ requiredField, menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.default
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderColor = ({ requiredField, menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.success
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderWidth = ({ requiredField, menuOpen, hasError }) => {
  if (menuOpen) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else if (requiredField) {
    return '2px'
  } else {
    return '1px'
  }
}

const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['marginBottom', 'marginRight', 'maxWidth'].includes(prop)
})`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '10px' : '0')};
  margin-right: ${({ marginRight }) => (marginRight ? '10px' : '0')};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  position: relative;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`

const ChosenValueWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
`

export const WrapperForValue = styled.div.withConfig({
  shouldForwardProp: prop => !['marginBottom', 'marginRight'].includes(prop)
})`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '10px' : '0')};
  margin-right: ${({ marginRight }) => (marginRight ? '10px' : '0')};
  position: relative;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`

const DownIcon = styled(ArrowDropDown)`
  width: 30px;
  height: 30px;
  color: ${th('background.mid')};
`

const DisabledInput = styled.input`
  background-color: ${th('backgrounds.light')};
  color: ${th('text.light')};
  font-family: ${th('fonts.light')};
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  font-size: 15px;
  height: 45px;
  padding: 15px;
  cursor: not-allowed;
`

const SelectedValue = styled.div`
  margin-right: 5px;
`

const ChosenValueLabel = styled.div`
  color: ${th('text.darkest')};
  font-family: ${th('fonts.bold')};
  font-size: 10px;
  line-height: 1;
  margin-bottom: 2px;
  margin-left: 8px;
  margin-top: 2px;
`

const SelectWithValue = styled.div`
  align-items: center;
  background: ${th('transparent.base')};
  border-radius: 4px;
  border-color: ${th('borders.required')};
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  cursor: default;
  display: flex;
  height: 45px;
  max-width: max-content;
  padding: 0 8px;
`

const SelectWithValueInner = styled.div`
  align-items: center;
  display: flex;
  padding: 0 8px;
`

const StyledSelect = styled(Select)`
  .Select__value-container {
    height: 100%;
    padding: 0;
  }

  .Select__value-container--has-value {
    line-height: 1;
    overflow: visible;
  }

  .Select__control {
    appearance: none;
    border-color: ${borderColor};
    border-radius: 3px;
    border-style: solid;
    border-width: ${borderWidth};
    cursor: pointer;
    font-family: ${th('fonts.light')};
    height: 45px;
    padding: 0 0 0 8px;

    &:hover {
      border-color: ${th('borders.required')};
    }

    &:hover ${DownIcon} {
      color: ${th('borders.required')};
    }
  }

  .Select__control.Select__control--is-focused {
    border-bottom-color: ${borderColorBottom};
    border-color: ${borderColor};
    box-shadow: none;

    &:hover {
      border-bottom-color: ${borderColorBottom};
      border-color: ${borderColor};
    }
  }

  .Select__placeholder {
    align-items: center;
    color: ${th('text.dark')};
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    transform: translate(0);
  }

  .Select__single-value {
    align-items: center;
    color: ${th('text.dark')};
    display: flex;
  }

  .Select__dropdown-indicator {
    color: ${th('background.mid')};
    padding: 0;

    &:focus,
    &:hover {
      color: ${th('background.mid')};
    }
  }

  .Select__menu {
    border-color: ${() => {
      if (isDgmt) {
        return th('borders.medium')
      }

      return th('secondary.base')
    }};
    border-radius: 3px;
    border-style: solid;
    border-width: ${borderWidth};
    box-shadow: none;
    color: ${th('text.dark')};
    margin: 2px 0 0;
    min-width: max-content;
    z-index: 3;
  }

  .Select__menu-list {
    padding: 0;
  }

  .Select__option {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .Select__option--is-focused {
    background: ${th('secondary.lightest')};
  }
`

const CloseIcon = styled(Close)`
  color: ${th('background.mid')};
  cursor: pointer;
  height: 30px;
  padding: 5px;
  width: 30px;

  &:hover {
    background: #eb452550;
    border-radius: 50%;
    color: ${th('text.error')};
  }

  &:hover ${SelectWithValue} {
    background: pink !important;
    border-color: ${th('borders.error')};
  }
`

const SingleSelectOptionIcon = ({ padding, src }) => {
  return (
    <SingleSelectOptionIconInner>
      <SingleSelectPaddedIcon padding={padding} src={src} />
    </SingleSelectOptionIconInner>
  )
}

const SingleSelectPaddedIcon = styled.img`
  border-radius: 50%;
  height: 30px;
  padding: ${props => props.padding || '0'};
  width: 30px;
`

const SingleSelectOptionLabel = styled.div``

const SingleSelectOptionIconInner = styled.span`
  border: 1px solid ${th('borders.dark')};
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  line-height: 1;
  overflow: hidden;
  margin-right: 10px;
  vertical-align: middle;
  width: 30px;
`

const IndicatorSeparator = () => {
  return null
}

const SelectOption = props => {
  // Disable onMouseMove and onMouseOver events for better performance when using icons/images
  // but only when over 20 items in the list
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps
  const hasTooManyOptions = props.options.length > 2000
  const innerPropsToUse = hasTooManyOptions ? rest : props.innerProps

  const newProps = { ...props, innerProps: innerPropsToUse }
  const icon = props.data.icon || props.data.image

  if (!icon) {
    return <components.Option {...newProps}>{props.data.label}</components.Option>
  }

  return (
    <components.Option {...newProps}>
      <SingleSelectOptionIcon padding={props.data.padding} src={icon} />
      <SingleSelectOptionLabel>{props.data.label}</SingleSelectOptionLabel>
    </components.Option>
  )
}

const ValueIcon = ({ icon, padding }) => {
  if (!icon) return null

  return <SingleSelectOptionIcon padding={padding} src={icon} />
}

const SingleValue = props => {
  const icon = props.data.icon || props.data.image

  if (!icon) {
    return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
  }

  return (
    <components.SingleValue {...props}>
      <SingleSelectOptionIcon padding={props.data.padding} src={icon} />
      <SingleSelectOptionLabel>{props.data.label}</SingleSelectOptionLabel>
    </components.SingleValue>
  )
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownIcon />
      </components.DropdownIndicator>
    )
  )
}

const valueFromId = (opts, id, optionalValue) => {
  if (!opts) return null

  const opt = id ? opts.find(o => o.value === id) : optionalValue || ''
  return opt
}

class StaticSelect extends Component {
  constructor(props) {
    super(props)
    this.state = { isExpanded: false, menuOpen: false }
  }

  expandInputField = () => {
    this.setState({ isExpanded: true })
  }

  // Integrate with Formik
  onChange = option => {
    const { field, form } = this.props

    if (form) {
      form.setFieldValue(field.name, option ? option.value : null)
    }

    if (this.props.onChange) {
      this.props.onChange(option)
    }
  }

  render() {
    const { isExpanded, menuOpen } = this.state
    const {
      chosenValueLabel,
      className,
      disabled,
      expandable,
      expandButtonText,
      field = {},
      forceLabel,
      form: { errors = {}, touched = {} } = {},
      label,
      marginBottom,
      marginRight,
      maxWidth,
      requiredField,
      subLabel,
      success,
      ...props
    } = this.props
    const displayableLabel = disabled ? label : requiredField ? `${label} *` : label

    if (disabled) {
      const value = valueFromId(this.props.options, field.value, this.props.value) || {}

      return (
        <Wrapper
          className={className}
          marginBottom={marginBottom}
          marginRight={marginRight}
          maxWidth={maxWidth}
        >
          {label && <Label htmlFor={field.name}>{displayableLabel}</Label>}
          {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <DisabledInput
            value={props.disabledPlaceholder || value.label || props.placeholder}
            onChange={() => {}}
          />
        </Wrapper>
      )
    }

    const val = valueFromId(this.props.options, field.value, this.props.value)
    const hasError = errors[field.name] || errors[`${field.name}_collection`]

    if (val) {
      return (
        <WrapperForValue marginBottom={marginBottom} marginRight={marginRight}>
          {forceLabel && displayableLabel && <Label htmlFor={field.name}>{displayableLabel}</Label>}

          <SelectWithValue>
            <ValueIcon icon={val.icon} padding={val.padding} />

            <ChosenValueWrapper>
              <ChosenValueLabel>{chosenValueLabel}</ChosenValueLabel>

              <SelectWithValueInner>
                <SelectedValue>{val.label}</SelectedValue>
              </SelectWithValueInner>
            </ChosenValueWrapper>

            <CloseIcon onClick={() => this.onChange({})} />
          </SelectWithValue>
        </WrapperForValue>
      )
    }

    if (!isExpanded && expandable && !field.value) {
      return (
        <div>
          <LinkButton onClick={() => this.expandInputField()}>
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{expandButtonText}</span>
          </LinkButton>
        </div>
      )
    }

    return (
      <Wrapper
        className={className}
        marginBottom={marginBottom}
        marginRight={marginRight}
        maxWidth={maxWidth}
      >
        {hasError ? (
          <Error>{hasError}</Error>
        ) : label ? (
          <Label htmlFor={field.name}>{displayableLabel}</Label>
        ) : null}

        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <StyledSelect
          requiredField={requiredField}
          classNamePrefix="Select"
          components={{
            IndicatorSeparator,
            DropdownIndicator,
            Option: SelectOption,
            SingleValue
          }}
          filterOption={createFilter({ ignoreAccents: false })}
          {...field}
          {...props}
          menuOpen={menuOpen}
          touched={!!touched[field.name]}
          hasError={!!hasError}
          hideSelectedOptions={true}
          isSearchable={false}
          onMenuOpen={() => this.setState({ menuOpen: true })}
          onMenuClose={() => this.setState({ menuOpen: false })}
          onChange={this.onChange}
          value={val}
          placeholder={val ? '' : props.placeholder}
        />

        {success && touched[field.name] && !hasError && <Success>{success}</Success>}
      </Wrapper>
    )
  }
}

export default StaticSelect
