import { createGlobalStyle } from 'styled-components'
import th from 'utils/themeHelper'

const GlobalText = createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
    outline: none;
  }


  /* :root {
  --shadow-color: 240deg 25% 73%;
  --shadow-elevation-low:
    0.1px 0.3px 0.3px hsl(var(--shadow-color) / 0.35),
    0.2px 0.4px 0.4px -1.8px hsl(var(--shadow-color) / 0.27),
    0.4px 1.1px 1.1px -3.7px hsl(var(--shadow-color) / 0.2);
  --shadow-elevation-medium:
    0.1px 0.3px 0.3px hsl(var(--shadow-color) / 0.29),
    0.2px 0.6px 0.6px -0.9px hsl(var(--shadow-color) / 0.25),
    0.4px 1.2px 1.2px -1.8px hsl(var(--shadow-color) / 0.22),
    1px 2.6px 2.6px -2.8px hsl(var(--shadow-color) / 0.18),
    2px 5.3px 5.4px -3.7px hsl(var(--shadow-color) / 0.14);
  --shadow-elevation-high:
    0.1px 0.3px 0.3px hsl(var(--shadow-color) / 0.3),
    0.3px 0.8px 0.8px -0.5px hsl(var(--shadow-color) / 0.28),
    0.5px 1.4px 1.4px -0.9px hsl(var(--shadow-color) / 0.26),
    0.9px 2.4px 2.4px -1.4px hsl(var(--shadow-color) / 0.24),
    1.5px 4px 4px -1.8px hsl(var(--shadow-color) / 0.21),
    2.4px 6.5px 6.5px -2.3px hsl(var(--shadow-color) / 0.19),
    3.8px 10px 10.1px -2.8px hsl(var(--shadow-color) / 0.17),
    5.6px 15px 15.1px -3.2px hsl(var(--shadow-color) / 0.15),
    8.1px 21.6px 21.8px -3.7px hsl(var(--shadow-color) / 0.13);
} */

:root {
  --shadow-color: 240deg 25% 80%;
  --shadow-elevation-low:
    0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.33),
    0.3px 0.6px 0.5px -2.5px hsl(var(--shadow-color) / 0.22),
    0.8px 1.6px 1.3px -5px hsl(var(--shadow-color) / 0.11);
  --shadow-elevation-medium:
    0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.28),
    0.3px 0.6px 0.5px -1.2px hsl(var(--shadow-color) / 0.22),
    0.7px 1.4px 1.2px -2.5px hsl(var(--shadow-color) / 0.17),
    1.7px 3.6px 3px -3.7px hsl(var(--shadow-color) / 0.11),
    3.8px 7.8px 6.5px -5px hsl(var(--shadow-color) / 0.06);
  --shadow-elevation-high:
    0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.26),
    0.3px 0.6px 0.5px -0.6px hsl(var(--shadow-color) / 0.23),
    0.4px 0.8px 0.7px -1.1px hsl(var(--shadow-color) / 0.21),
    0.7px 1.5px 1.2px -1.7px hsl(var(--shadow-color) / 0.18),
    1.3px 2.7px 2.2px -2.2px hsl(var(--shadow-color) / 0.16),
    2.4px 4.9px 4.1px -2.8px hsl(var(--shadow-color) / 0.13),
    3.9px 8px 6.7px -3.3px hsl(var(--shadow-color) / 0.1),
    6.1px 12.5px 10.4px -3.9px hsl(var(--shadow-color) / 0.08),
    8.9px 18.3px 15.3px -4.4px hsl(var(--shadow-color) / 0.05),
    12.6px 25.9px 21.6px -5px hsl(var(--shadow-color) / 0.03);
}



  #pageWrapperBlurred > * {
    filter: blur(3px);
  }

  /* IE11 polyfill */
  main { display: block; }

  body,
  html {
    padding: 0;
    margin: 0;
    font-family: 'ProximaNova', arial, sans-serif;
    color: ${th('text.dark')};
    font-size: 15px;
  }

  h1 {
    font-size: 55px;
    font-weight: normal;
  }
  
  h2 {
    font-size: 33px;
    font-family: ${th('fonts.bold')};
  }
  
  h3 {
    font-size: 27px;
    font-family: ${th('fonts.bold')};
  }
  
  h4 {
    font-size: 18px;
    font-family: ${th('fonts.bold')};
  }
  
  h5 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: normal;
  }

  p {
    font-size: 15px;
    line-height: 1.5;
    margin-top: 0;
  }
  
  label {
    font-size: 13px;
  }
  
  a {
    font-size: 15px;
    color: ${({ theme }) => theme.primary.base};
    text-decoration: none;
  }

  .Linkify {
    white-space: pre-line;
    line-height: 1.35em;
  }

  .unstyledLink {
    text-decoration: none;
  }

  .dashboardLink {
    text-decoration: none;
    color: #ffffff;
  }

  .DayPickerInput {
    display: block;
  }

 

  .role-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0 2px 5px #00000014;
    overflow: visible;
  }

  .role-icon-benefactor {
    background: linear-gradient(to right, #66BAA840 0%, #66BAA840 100%);
  }
  .role-icon-beneficiary {
    background: linear-gradient(to right, #EAADD440 0%, #EAADD440 100%);
  }
  .role-icon-opp-admin {
    background: linear-gradient(to right, #8CCA1140 0%, #8CCA1140 100%);
  }
  .role-icon-benefactor:hover {
    background: linear-gradient(to right, #66BAA8 0%, #66BAA8 100%);
  }
  .role-icon-beneficiary:hover {
    background: linear-gradient(to right, #EAADD4 0%, #EAADD4 100%);
  }
  .role-icon-opp-admin:hover {
    background: linear-gradient(to right, #8CCA11 0%, #8CCA11 100%);
  }

  // Payfast modal
  .payfast_compact_payment_modal, #payfast_div_container {
    z-index: 9999 !important
  }

  //
  //
  // Masonry styling for StoryFeed
  //
  //

  .camp-masonry,
  .opp-masonry, 
  .org-masonry,
  .user-masonry {
    display: flex;
    justify-content: space-between;
    width: 100%; 
  }
  
  .camp-masonry_column,
  .opp-masonry_column,
  .org-masonry_column,
  .user-masonry_column {
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
  }

  .camp-masonry_column > div,
  .opp-masonry_column > div,
  .org-masonry_column > div,
  .user-masonry_column > div, { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 10px;
    
    @media screen and (min-width: 769px) {
      margin-bottom: 15px;
    }
  }

  .two_col_masonry_column {
    width: calc(50% - 10px)!important;
  }
  
  .four_col_masonry_column {
    width: calc(25% - 10px)!important;
  }

  // 
  // 
  // Pagination styling
  // P.S - Sorry for the mess
  // 
  // 

  .pagination {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    
    @media screen and (min-width: 786px) {
      display: block;
    }
  }

  .pagination ul {
    display: inline-block;
    margin: 0 auto;
  }

  .pagination li {
    border: 1px solid ${th('text.dark')};
    margin: 5px;
    display: none;
    padding: 4px 0; 

    a {
      padding: 12px 12px;
      color: ${th('text.dark')};
      font-size: 12px;
    }

    &:hover a {
      background: white;
      color: ${th('text.dark')};
      cursor: pointer;
      padding: 6px 15px 5px;

      /* Prevent blue highlighting of page numbers from multiple clicking in browsers */
      -webkit-touch-callout: none; 
      -webkit-user-select: none; 
      -khtml-user-select: none; 
      -moz-user-select: none; 
      -ms-user-select: none; 
      user-select: none;
    }

    @media screen and (min-width: 786px) {
      display: inline-block;
      margin: 15px 8px 15px 0px;
      padding: 8px 0;

      a {
        padding: 6px 15px 5px;
      }

      &:hover a {
        padding: 6px 15px 5px;
      }
    }
  }

  .pagination .next,
  .pagination .previous {
    display: inline-block
  }

  .pagination > .active {
    background-color: ${th('text.dark')};
    
    &:hover a {
      padding: 6.5px 15px 5px;
    }

    @media screen and (min-width: 786px) {
      &:hover a {
        padding: 10px 15px 9px;
      } 
    }
  }
  
  .pagination > .disabled {
    border-color: ${th('borders.medium')}!important;
    background-color: ${th('backgrounds.mid')};
    cursor: not-allowed;

    &:hover a {
      padding: 6px 12px 5px;
      border-color: ${th('borders.medium')}!important;
      background-color: ${th('backgrounds.mid')};
    }

    @media screen and (min-width: 786px) {
      &:hover a {
        padding: 6px 15px 5px;
      }
    }
  }
  
  .pagination > .disabled > a {
    color: ${th('borders.light')}!important;
    cursor: not-allowed!important;
  }
  
  .pagination > .active a {
    color: white;
    font-size: 12px;
  }

  .ReactCrop {
    margin-top: 10px;
  }

  // 
  // 
  // react-phone-number-input
  // 
  // 
  
  .PhoneInput {
    display: flex;
    font-size: 15px;
    font-family: ${th('fonts.bold')};
    border-radius: 3px;
    color: ${th('text.dark')};

    &:hover {
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.borders.error : theme.borders.dark};
    }

    &:focus-within {
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.borders.error : theme.secondary.base};
    }

    &Country {
      height: 43px;
      padding: 15px;
      border-radius: 3px 0px 0px 3px;

      &--native {
        margin: 0;
      }
    }

    &Icon {
      position: relative;

      svg {
        position: absolute;
      }
    }

    &Input {
      border: none;
      width: 100%;
      height: 43px;
      padding: 15px;
      padding-left: 0;
      font-size: 15px;
      border-radius: 3px;
      color: ${th('text.dark')} !important;
      font-family: ${th('fonts.light')};

      &::placeholder {
        color: ${th('text.light')};
      }
    }
  }

  // SEF contract styles

  .underlined {
    min-width: 50px;
    border-bottom: 1px solid #000;
    display: inline-block;
  }

  .SinglePage {
    display: block;
    page-break-after: always;
    position: relative;
  }

  .SigHolder {
    width: 100%;
  }

  .Signature {
    color: #000;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 10px;
    display: block;
    position: relative;
    font-family: 'Dancing Script', cursive;
  }

  .SigsHolder {
    display: flex;
    justify-content: space-around;
  }

  .realSignature {
    height: 20px;
    width: 20px;
  }

  .BoldFont {
    color: #000;
    display: inline;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 4px;
    margin-right: 4px;
  }

  .SefEmploymentAgreementCentered {
    margin-bottom: 5px;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
  }

  .SefEmploymentAgreementBulletPoint {
    margin-left: 3%;
    margin-bottom: 15px;
  }

  .ParticipantDetailsBlock {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .SefEmploymentAgreementBulletPointNumber {
    display: inline-block;
    margin-right: 15px;
  }

  .SefEmploymentAgreementSpacer {
    width: 100%;
    height: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
    display: block;
  }

  .SefEmploymentAgreementStrongUppercase {
    color: #000;
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
    position: relative;
  }
  .SefEmploymentAgreementStrongUppercase {
    color: #000;
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
    position: relative;
  }

  .SefEmploymentAgreementStrongUppercaseCenter {
    color: #000;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    position: relative;
    text-align: center;
  }

  .fc-daygrid-day:hover,
  .fc-weekend-day:hover {
    background: #E0F3BB;
    cursor: pointer;
  }

  .fc-weekend-day {
    background-color:rgb(231, 245, 202) !important;
  }
  
  .fc-disabled-day {
    background-color:rgb(176, 174, 174) !important;
    color: red !important;
    pointer-events: none;
  }
`

export default GlobalText
