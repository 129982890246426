import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// styles
import { ComponentError, InnerWrapper, Input, Wrapper, WordLimitWrapper } from './styles'

// Load components synchronously
import { Error, Label, RequiredTag, SubLabel, Success } from 'components/InputLabels/InputLabels'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

// utils
import stringUtils from 'utils/string'

// icons
import { PlusSign } from 'components/Icons/Icons'

const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

const ExpandButtonWrapper = styled.div`
  position: ${props => (props.floatExpandButton ? 'absolute' : 'relative')};
  right: 0;
  top: 0;
`

class TextArea extends Component {
  state = { isExpanded: null }

  expandTextArea = () => {
    this.setState({ isExpanded: true })
  }

  render() {
    const { isExpanded } = this.state
    const {
      disabled,
      expandable,
      expandSubLabel,
      expandButtonText,
      field,
      floatExpandButton,
      form: { touched, errors },
      wordLimit,
      requiredField,
      label,
      subLabel,
      success
    } = this.props

    if (expandable && requiredField) {
      return (
        <ComponentError>
          TextArea component cannot accept both the <code>expandable</code> prop and the{' '}
          <code>requiredField</code> prop together.
        </ComponentError>
      )
    }

    const { splicedString, wordsRemaining } = stringUtils.trimText({
      string: field.value || '',
      wordLimit
    })
    const word = wordsRemaining === 1 ? 'word' : 'words'
    const nearingLimit = wordLimit && wordLimit < 20
    const displayableLabel = disabled ? label : requiredField ? `${label} *` : label
    const hasError = touched && errors[field.name]

    // show expand button when needed, unless the field has a value already
    if (!isExpanded && expandable && !field.value) {
      return (
        <div style={{ position: 'relative' }}>
          <Label htmlFor={field.name}>{displayableLabel}</Label>
          {expandSubLabel && <SubLabel subLabel={expandSubLabel}>{expandSubLabel}</SubLabel>}

          <ExpandButtonWrapper floatExpandButton={floatExpandButton}>
            <LinkButton disabled={disabled} onClick={() => this.expandTextArea()}>
              {!floatExpandButton && (
                <PlusIconWrapper>
                  <PlusSign />
                </PlusIconWrapper>
              )}

              <span>{expandButtonText}</span>
            </LinkButton>
          </ExpandButtonWrapper>
        </div>
      )
    }

    return (
      <Wrapper>
        {hasError ? (
          <Error>{errors[field.name]}</Error>
        ) : label ? (
          <Label htmlFor={field.name}>{displayableLabel}</Label>
        ) : null}
        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <InnerWrapper>
          {wordLimit && (
            <WordLimitWrapper nearingLimit={nearingLimit}>
              {wordsRemaining} {word} remaining
            </WordLimitWrapper>
          )}

          <Input
            disabled={disabled}
            wordLimit={wordLimit}
            {...field}
            {...this.props}
            value={splicedString}
            requiredField={requiredField}
            hasError={!!errors[field.name]}
          />
        </InnerWrapper>

        {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
      </Wrapper>
    )
  }
}

TextArea.propTypes = {
  expandable: PropTypes.bool,
  label: PropTypes.string,
  requiredField: PropTypes.bool,
  subLabel: PropTypes.string,
  wordLimit: PropTypes.number
}

export default TextArea
