import React from 'react'
import { Link } from '@reach/router'
import styled, { css, keyframes } from 'styled-components'

// utils
import th from 'utils/themeHelper'

// config
import { isSef } from 'config/localization'

// icons
import { Settings } from 'styled-icons/ionicons-outline'
import { ChevronCircleLeft } from '@styled-icons/fluentui-system-filled/ChevronCircleLeft'
import { ChevronCircleRight } from '@styled-icons/fluentui-system-filled/ChevronCircleRight'

const pulseAnimation = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`

const ShortPulse = css`
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${pulseAnimation};
  animation-timing-function: linear;
`

export const ShortPulseTitleSkeleton = styled.div`
  width: 100%;
  height: 26px;
  margin-top: 12px;
  border-radius: 7px;
  ${ShortPulse};

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`

export const DashboardHomePageMobileContainer = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'displayMobile'
})`
  align-items: center;
  display: ${({ displayMobile }) => (displayMobile ? 'flex' : 'none')};
  margin: 80px 10px 0;

  @media screen and (min-width: 769px) {
    display: none;
    margin: 85px 20px 0;
  }
`

export const DashboardHeading = styled.h3`
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  line-height: 1.25em;
  margin: 85px 10px 0;

  @media screen and (min-width: 769px) {
    font-size: 20px;
    margin: 85px auto 0;
  }
`

export const DashboardMobileHeading = styled.h3`
  font-family: ${th('fonts.bold')};
  margin: 0;
  flex: 1;
  line-height: 1.25em;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

export const OrgWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || '50px'};
  width: 200px;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const OrgDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 769px) {
    align-items: flex-start;
    margin-left: 20px;
  }
`

export const SidebarFooterLogoWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'sidebarIsCollapsed'
})`
  bottom: 10px;
  display: flex;
  justify-content: ${({ sidebarIsCollapsed }) => (sidebarIsCollapsed ? 'center' : 'flex-start')};
  left: ${({ sidebarIsCollapsed }) => (sidebarIsCollapsed ? '0' : '30px')};
  position: absolute;
  width: 100%;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const DashCollapseIconWrapper = styled.div`
  bottom: 10px;
  cursor: pointer;
  display: block;
  height: 28px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 50px;

  & > svg {
    fill: transparent;
    position: relative;
    stroke: ${th('text.dark')};
    width: 28px;
  }

  &:hover {
    & > svg {
      background: ${th('transparent.base')};
      border-radius: 50%;
    }
  }
`

export const DashSettingsIconWrapper = styled.div`
  border-color: ${th('borders.medium')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  cursor: pointer;
  display: block;
  margin-left: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  height: 26px;
  width: 26px;

  &:hover {
    border-color: ${th('borders.darkPill')};
  }

  & > svg {
    position: relative;
    top: 4px;
    width: 15px;
  }
`

export const DashSettingsIcon = ({ toggleDashSidebarSettings }) => {
  return (
    <DashSettingsIconWrapper onClick={() => toggleDashSidebarSettings()}>
      <Settings />
    </DashSettingsIconWrapper>
  )
}

export const DashCollapseIcon = ({ sidebarIsCollapsed, toggleSidebarCollapse }) => {
  return (
    <DashCollapseIconWrapper onClick={() => toggleSidebarCollapse()}>
      {sidebarIsCollapsed ? <ChevronCircleRight /> : <ChevronCircleLeft />}
    </DashCollapseIconWrapper>
  )
}

export const DashboardSideNavOuter = styled.div.withConfig({
  shouldForwardProp: prop =>
    !['displayMobile', 'sidebarIsCollapsed', 'dashboardSettingsVisible'].includes(prop)
})`
  display: ${({ displayMobile }) => (displayMobile ? 'flex' : 'none')};
  margin-top: 15px;
  order: 1;
  z-index: ${({ sidebarIsCollapsed }) => (sidebarIsCollapsed ? '3' : '2')};

  @media screen and (min-width: 769px) {
    display: ${({ dashboardSettingsVisible }) => (dashboardSettingsVisible ? 'flex' : 'block')};
    height: 100%;
    justify-content: center;
    margin-top: 0;
    position: ${({ dashboardSettingsVisible }) =>
      dashboardSettingsVisible ? 'relative' : 'fixed'};
    width: ${({ dashboardSettingsVisible, sidebarIsCollapsed }) =>
      dashboardSettingsVisible ? '100%' : sidebarIsCollapsed ? '50px' : isSef ? '160px' : '200px'};

    // scrollbar hiding
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
`

export const FooterLink = styled(Link)`
  display: flex;
  width: 70px;
`

export const SidebarLinkButton = styled(Link)`
  margin-bottom: 1em;
`

export const LogoWrapper = styled.div`
  background: white;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 8px;
  overflow: hidden;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  position: relative;
`

export const Logo = styled.img`
  height: 100%;
  width: 70px;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`

export const LogoSkeleton = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  ${ShortPulse};
`

export const OrgName = styled.h4`
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${th('text.dark')};

  @media screen and (max-width: 769px) {
    margin-top: 0;
  }
`

export const OrgNameLink = styled(Link)`
  font-size: 13px;
  text-decoration: none;
  color: ${th('text.dark')};

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`

export const DashSidebarConfigOption = styled.div`
  align-items: center;
  border-radius: 8px;
  color: ${th('text.dark')};
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  flex-wrap: nowrap;
  font-family: ${th('fonts.regular')};
  font-size: 13px;
  margin: 0 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  & > svg {
    height: 20px;
    margin-right: 10px;
    position: relative;
    width: 20px;
  }
`

export const NavItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 0 20px;
  width: 100%;

  @media screen and (max-width: 769px) {
    flex-direction: row;
    padding: 0 10px;
  }
`

export const BrowniePointsLogo = styled.img`
  width: 70px;
  height: auto;
  padding: 0;
`

export const DashboardSettingsWrapper = styled.div`
  margin: 20px 10px 0;
  overflow-y: scroll;
  width: 90%;

  @media screen and (min-width: 769px) {
    margin: 20px 0 0;
    width: 500px;
  }
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-decoration: none;
  font-family: ${th('fonts.regular')};
  font-size: 13px;
  color: white;
  text-transform: uppercase;
  padding: 5px 0px;
  line-height: 15px;
  width: 100%;
  border: none;
  cursor: pointer;
  background: transparent;
`

export const OrgText = styled.p`
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

export const DashboardSidebarWrapper = styled.div`
  width: 220px;
  padding: 20px 0px;

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`

export const NavLinkWrapper = styled.div`
  padding: 5px 15px;
`

export const DashboardSidebarLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${th('borders.light')};
`
