import React from 'react'
import { useWindowScroll } from 'react-use'
import styled, { keyframes } from 'styled-components'
import darken from 'polished/lib/color/darken'
import { Link } from '@reach/router'

// icons
import { ArrowToTop } from '@styled-icons/boxicons-regular'

// utils
import th from 'utils/themeHelper'

const blink = keyframes`
  33% {
    background: #787878;
  }
`

const noBlink = keyframes``

const scrollToTop = (topPosition = 0) => {
  document.body.scroll({
    top: topPosition,
    left: 0,
    behavior: 'smooth'
  }) // For Safari

  document.documentElement.scroll({
    top: topPosition,
    left: 0,
    behavior: 'smooth'
  }) // For Chrome, Firefox, IE and Opera
}

export const LogoLink = styled.div.withConfig({
  shouldForwardProp: prop => !['homepage', 'isSef'].includes(prop)
})`
  cursor: pointer;
  text-decoration: none;

  @media screen and (min-width: 769px) {
    margin-left: ${({ homepage, isSef }) => (homepage && isSef ? '10px' : '0')};
    margin-top: ${({ homepage, isSef }) => (homepage && isSef ? '10px' : '0')};
  }
`

export const NavBarRightSection = styled.div`
  display: flex;
`

const ScrollToTopWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'sticky'
})`
  display: none;

  @media screen and (max-width: 769px) {
    background: white;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 35px;
    margin-right: 10px;
    opacity: ${({ sticky }) => (sticky ? '100%' : '0%')};
    padding: 5px;
    width: 35px;
  }
`

export const ScrollToTop = () => {
  const { y } = useWindowScroll()
  const checkpoint = 85
  const sticky = y > checkpoint

  return (
    <ScrollToTopWrapper sticky={sticky} onClick={() => scrollToTop()}>
      <ArrowToTop />
    </ScrollToTopWrapper>
  )
}

export const MainNavWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['hidden', 'homepage', 'transparent'].includes(prop)
})`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  min-height: 65px;
  position: ${({ homepage, transparent }) => (homepage && transparent ? 'absolute' : 'fixed')};
  width: 100%;
  z-index: 9999;
`

export const NavLinkItemWrapper = styled.div`
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  margin-left: 10px;
  padding: 4px 16px;

  &:hover {
    background: white;
    color: ${th('text.dark')};
    cursor: pointer;
  }
`

export const NavLinkItem = styled.div`
  background-color: transparent;
  border: none;
  font-family: ${props => (props.active ? th('fonts.bold') : th('fonts.regular'))};
  font-size: 18px;
  line-height: 1.25em;
  padding: 0;
  text-decoration: none;

  /* Prevent blue highlighting of page numbers from multiple clicking in browsers */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const DgmtNewPboButton = styled.button`
  background: ${th('secondary.base')};
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  padding: 8px 24px;
  vertical-align: middle;

  // &:hover {
  //   background: ${th('secondary.base')};
  // }

   @media screen and (min-width: 769px) {
    &:hover {
      background: radial-gradient(100% 100% at 100% 5%, #FFA64D 0%, #D58B42 100%);
      color: white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0px 2px 2px rgba(45, 35, 66, 0.4), 0px 4px 4px -3px rgba(45, 35, 66, 0.3),
        inset 0px -2px 0px rgba(58, 65, 111, 0.5);
    }
  }
`

export const ChampionBtn = styled.button`
  background: ${th('primary.base')};
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  padding: 8px 24px;
  vertical-align: middle;

  &:hover {
    background: ${th('secondary.base')};
  }
`

export const NavLinksWrapper = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const PricingDropdownWrapper = styled.div`
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  color: ${th('text.dark')};
  margin-left: -18px;
  position: absolute;
  top: 55px;
`

export const PricingDropdownItem = styled.div`
  border-bottom: 1px solid ${th('borders.default')};
  padding: 20px 40px;

  &:hover {
    background: ${th('backgrounds.light')};
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

export const FeedbackIconLink = styled(Link)`
  display: block;
  text-decoration: none;
`

export const NavBar = styled.div.withConfig({
  shouldForwardProp: prop => !['homepage', 'transparent', 'isLoading'].includes(prop)
})`
  background-color: ${({ homepage, transparent }) =>
    homepage && transparent ? 'transparent' : th('text.dark')};
  height: 65px;
  padding: 0 20px;
  width: 100%;

  -webkit-animation: ${({ isLoading }) => (isLoading ? blink : noBlink)} 1.6s infinite linear;
  animation: ${({ isLoading }) => (isLoading ? blink : noBlink)} 1.6s infinite linear;

  @media screen and (max-width: 820px) {
    input {
      width: 250px;
    }
  }

  @media screen and (max-width: 520px) {
    input {
      display: none;
    }
  }
`

export const NavBarContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

export const NavBarContentItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => props.justifyContent || 'flex-start'};
`

export const TranslateIconContainer = styled.div`
  border: 1px solid ${th('borders.medium')};
  border-radius: 4px;
  color: white;
  height: 35px;
  margin-right: 10px;
  padding: 5px;
  width: 35px;

  &:hover {
    background: ${th('backgrounds.light')};
    border: 1px solid ${th('borders.dark')};
    color: ${th('text.dark')};
    cursor: pointer;
  }
`

export const SefNavButton = styled.button`
  background: ${th('primary.base')};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px;
  text-align: ${props => (props.right ? 'right' : props.left ? 'left' : 'center')};

  @media screen and (min-width: 769px) {
    padding: 12px 20px;
  }

  &:hover {
    background: ${({ theme }) => darken(0.05, theme.secondary.base)};
    border-color: ${({ theme }) => darken(0.05, theme.secondary.base)};
    transition: 0.3s background 0.3s border;
  }
`

export const SefNavButtonHint = styled.div`
  color: ${th('text.dark')};
  display: none;
  font-size: 15px;
  margin-right: 12px;

  @media screen and (min-width: 769px) {
    display: block;
  }
`

export const SefNavButtonWrapper = styled.div`
  align-items: center;
  display: flex;

  @media screen and (min-width: 769px) {
    margin-right: 60px;
  }
`

export const SefNavButtonContents = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
`

export const SvgIconWrapper = styled.div`
  height: 20px;
  margin-right: 10px;
  width: 20px;

  @media screen and (min-width: 780px) {
    height: 25px;
    width: 25px;
  }

  svg {
    color: white;
  }
`

export const TitleText = styled.p`
  color: white;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  text-align: center;

  @media screen and (min-width: 780px) {
    font-size: 17px;
    text-align: ${props => (props.center ? 'center' : 'left')};
  }
`
