import React, { Component } from 'react'
import styled from 'styled-components'

// assets
import { Check } from '@styled-icons/feather'
import { StarFill } from '@styled-icons/bootstrap'

// utils and config
import breakpoints from 'utils/breakpoints'
import th from 'utils/themeHelper'

const BaseWrapper = styled.div`
  border-radius: 4px;
  background-color: white;
  border: 1px solid ${th('borders.default')};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  position: relative;

  @media (min-width: ${breakpoints.formMiniMapDisplay}) {
    margin-bottom: 20px;
    margin-right: ${({ fullWidth }) => (fullWidth ? '0' : '15px')};
  }
`

const Wrapper = styled(BaseWrapper).withConfig({
  shouldForwardProp: prop => prop !== 'fullWidth'
})``

const CompletedIconWrapper = styled.div`
  height: 30px;
  width: 30px;
  padding: 4px;
  border-radius: 50%;
  background: ${th('secondary.base')};
  color: white;
  stroke-width: 2px;
`

const DashboardSectionBlockHeader = styled.div.withConfig({
  shouldForwardProp: prop =>
    !['disabled', 'displayUpgradePill', 'hasErrorOrWarning', 'flex'].includes(prop)
})`
  align-items: center;
  background: ${({ disabled, displayUpgradePill, hasErrorOrWarning }) =>
    disabled
      ? th('backgrounds.mid')
      : displayUpgradePill
      ? th('backgrounds.dark')
      : hasErrorOrWarning
      ? th('borders.error')
      : th('secondary.dark')};
  color: ${({ disabled }) => (disabled ? th('text.dark') : 'white')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  line-height: 15px;
  overflow-x: auto;
  padding: 15px;
  position: relative;

  @media screen and (min-width: 769px) {
    padding: 20px;
  }
`

const DashboardSectionBlockContent = styled.div`
  background-color: ${props => props.customPillSectionBgColor || 'white'};
  line-height: 1.5em;
  padding: 15px;
  position: relative;

  @media screen and (min-width: 769px) {
    padding: 20px;
  }
`

const ChildrenWrapper = styled.div`
  display: ${props => (props.backgroundImage ? 'flex' : 'block')};
  flex-direction: column;
  min-height: ${props => (props.backgroundImage ? '200px' : 'auto')};
  position: relative;
`

const DashboardSectionBlockBackground = styled.div`
  display: none;

  @media screen and (min-width: 769px) {
    // background-image: ${props =>
      `linear-gradient(to right, white 30%, #ffffffee 60%, #ffffff55), linear-gradient(to top, #ffffffee 10%, #ffffff55), url(${props.backgroundImage})`};
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`

const CollapsePillWrapper = styled.div`
  display: block;
  // So collapsable components have the same padding for the header section
  margin-bottom: -13.5px;
  margin-top: -13.5px;
`

const UpgradePill = styled.div`
  border: 2px solid gold;
  border-radius: 3px;
  color: gold;
  cursor: pointer;
  display: flex;
  margin-left: 1em;
  padding: 4.5px 8px;

  &:hover {
    color: #5c5c5c;
    background: gold;

    > div > svg {
      fill: #5c5c5c;
    }
  }
`

const CustomPill = styled.div`
  background-color: ${props => props.customPillBgColor || 'gold'};
  border: 2px solid ${props => props.customPillBgColor || 'gold'};
  border-radius: 3px;
  color: ${props => props.customPillColor || '#5c5c5c'};
  cursor: ${props => (props.cursorIsPointer ? 'pointer' : 'default')};
  display: flex;
  margin-left: 1em;
  padding: 4.5px 8px;

  > div > svg {
    fill: ${props => props.customPillColor || '#5c5c5c'};
  }
`

const UpgradePillLabel = styled.div`
  line-height: 1.5em;
  margin-left: 0.35em;
`

const UpgradePillIcon = styled.div`
  color: gold;
  height: 15px;
  margin-top: 2px;
  width: 15px;
`

const FlexHeader = styled.div`
  flex: 1;
`

const FlexHeaderPill = styled.div`
  background: ${props =>
    props.hasErrorOrWarning
      ? 'white'
      : props.disabled && props.active
      ? '#eee'
      : props.active
      ? 'white'
      : 'none'};
  color: ${props =>
    props.disabled
      ? th('backgrounds.dark')
      : props.active
      ? th('backgrounds.dark')
      : props.hasErrorOrWarning
      ? th('text.error')
      : 'white'};
  border: 1px solid
    ${props =>
      props.disabled
        ? th('backgrounds.dark')
        : props.active
        ? 'white'
        : props.hasErrorOrWarning
        ? th('text.error')
        : 'white'};
  border-radius: 3px;
  margin-right: 1em;
  padding: 8px 16px;
  white-space: nowrap;

  &:hover {
    background: white;
    border-color: ${props => (props.active ? 'none' : th('backgrounds.dark'))};
    color: ${th('backgrounds.dark')};
    cursor: pointer;
  }
`

const InlineLink = styled.a`
  color: white;
`

class DashboardSectionBlock extends Component {
  state = { activeSection: null }

  setSectionActive({ id }) {
    this.setState({ activeSection: id })
  }

  deriveUnlockLink({ orgTypeOfChosenOrg, selectedOrg }) {
    if (!orgTypeOfChosenOrg) return '/pricing/nonprofits?billing=annual'

    const orgIdAppend = selectedOrg && selectedOrg.id ? `&orgId=${selectedOrg.id}` : ''

    if (orgTypeOfChosenOrg.name.includes('Nonprofit')) {
      return `/pricing/nonprofits?billing=annual${orgIdAppend}`
    }

    if (orgTypeOfChosenOrg.name.includes('School')) {
      return `/pricing/education?billing=annual${orgIdAppend}`
    }

    if (orgTypeOfChosenOrg.name.includes('Business')) {
      return `/pricing/business?billing=annual${orgIdAppend}`
    }

    return '/pricing/nonprofits'
  }

  render() {
    const { activeSection } = this.state
    const {
      backgroundImage,
      children,
      customPillBgColor,
      customPillColor,
      customPillHandleCancel,
      customPillIcon: CustomPillIcon,
      customPillLabel,
      customPillSectionBgColor,
      disabled,
      displayCustomPill,
      displayUpgradePill,
      errors,
      fullWidth,
      hasErrorOrWarning,
      header,
      headers,
      id,
      isActive,
      multiOpt,
      orgTypeOfChosenOrg,
      selectedOrg
    } = this.props
    const unlockLink = this.deriveUnlockLink({ orgTypeOfChosenOrg, selectedOrg })
    const errorsKeys = errors ? Object.keys(errors) : []

    if (multiOpt) {
      const activeFormSection = headers.find(h => h.id === activeSection) || headers[0]
      const activeFormSectionId = activeFormSection.id
      const activeFormFields = children.filter(c => activeFormSectionId === c.props.formSection)

      return (
        <Wrapper fullWidth={fullWidth} id={id}>
          <DashboardSectionBlockHeader disabled={disabled} flex>
            {headers.map(({ id, field_ids = [], label }) => (
              <FlexHeaderPill
                disabled={disabled}
                active={id === activeFormSectionId}
                hasErrorOrWarning={errorsKeys.some(k => field_ids.includes(k))}
                key={`header_${id}`}
                onClick={() => this.setSectionActive({ id })}
              >
                {label}
              </FlexHeaderPill>
            ))}
          </DashboardSectionBlockHeader>

          <DashboardSectionBlockContent>{activeFormFields}</DashboardSectionBlockContent>
        </Wrapper>
      )
    }

    if (isActive) {
      return (
        <Wrapper id={id}>
          <DashboardSectionBlockHeader
            displayUpgradePill={displayUpgradePill}
            hasErrorOrWarning={hasErrorOrWarning}
            flex
          >
            <FlexHeader>{header}</FlexHeader>

            {displayCustomPill && (
              <CollapsePillWrapper onClick={customPillHandleCancel}>
                <CustomPill
                  cursorIsPointer={customPillHandleCancel}
                  customPillBgColor={customPillBgColor}
                  customPillColor={customPillColor}
                >
                  <UpgradePillIcon>
                    <CustomPillIcon />
                  </UpgradePillIcon>
                  <UpgradePillLabel>{customPillLabel}</UpgradePillLabel>
                </CustomPill>
              </CollapsePillWrapper>
            )}

            {displayUpgradePill && (
              <CollapsePillWrapper>
                <InlineLink href={unlockLink} target="_blank">
                  <UpgradePill>
                    <UpgradePillIcon>
                      <StarFill />
                    </UpgradePillIcon>
                    <UpgradePillLabel>Unlock</UpgradePillLabel>
                  </UpgradePill>
                </InlineLink>
              </CollapsePillWrapper>
            )}

            <CompletedIconWrapper>
              <Check />
            </CompletedIconWrapper>
          </DashboardSectionBlockHeader>

          <DashboardSectionBlockContent customPillSectionBgColor={customPillSectionBgColor}>
            {children}
          </DashboardSectionBlockContent>
        </Wrapper>
      )
    }

    return (
      <Wrapper id={id} fullWidth={fullWidth}>
        <DashboardSectionBlockHeader
          disabled={disabled}
          displayUpgradePill={displayUpgradePill}
          hasErrorOrWarning={hasErrorOrWarning}
          flex
        >
          <FlexHeader>{header}</FlexHeader>

          {displayCustomPill && (
            <CollapsePillWrapper onClick={customPillHandleCancel}>
              <CustomPill
                cursorIsPointer={customPillHandleCancel}
                customPillBgColor={customPillBgColor}
                customPillColor={customPillColor}
              >
                <UpgradePillIcon>
                  <CustomPillIcon />
                </UpgradePillIcon>
                <UpgradePillLabel>{customPillLabel}</UpgradePillLabel>
              </CustomPill>
            </CollapsePillWrapper>
          )}

          {displayUpgradePill && (
            <CollapsePillWrapper>
              <InlineLink href={unlockLink} target="_blank">
                <UpgradePill>
                  <UpgradePillIcon>
                    <StarFill />
                  </UpgradePillIcon>
                  <UpgradePillLabel>Unlock</UpgradePillLabel>
                </UpgradePill>
              </InlineLink>
            </CollapsePillWrapper>
          )}
        </DashboardSectionBlockHeader>

        <DashboardSectionBlockContent customPillSectionBgColor={customPillSectionBgColor}>
          {false && backgroundImage && (
            <DashboardSectionBlockBackground backgroundImage={backgroundImage} />
          )}

          {false && <ChildrenWrapper backgroundImage={backgroundImage}>{children}</ChildrenWrapper>}

          {children}
        </DashboardSectionBlockContent>
      </Wrapper>
    )
  }
}

export default DashboardSectionBlock
