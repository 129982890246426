import styled from 'styled-components'
import th from 'utils/themeHelper'

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop =>
    !['hasError', 'touched', 'requiredField', 'subLabel', 'fullWidth'].includes(prop)
})`
  display: block;

  @media screen and (min-width: 600px) {
    display: inline-block;
    min-width: ${props => (props.fullWidth ? '100%' : '500px')};
  }

  .PhoneInput,
  .PhoneInputInput {
    height: ${({ requiredField }) => (requiredField ? '47px' : '45px')};
    background: ${({ disabled, theme }) => (disabled ? theme.backgrounds.light : 'white')};
    border-width: ${({ requiredField }) => (requiredField ? '2px' : '1px')};
    border-style: solid;
    border-color: ${({ hasError, theme, requiredField }) =>
      hasError
        ? theme.borders.error
        : requiredField
        ? theme.borders.required
        : theme.borders.default};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};

    &:hover {
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.borders.error : theme.borders.dark};
    }
  }

  .PhoneInputInput {
    border-left: none;
    border-right: none;
  }
`

export const PhoneNumberInputLabel = styled.span`
  line-height: 1;
  display: block;
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  font-size: 13px;
  margin-bottom: 7px;
  margin-left: 2px;
`
