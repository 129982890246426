import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'
import spacing from 'utils/spacing'

// icons
import { Close } from '@styled-icons/material/Close'

export const Outer = styled.div.withConfig({
  shouldForwardProp: prop => !['canHaveSiblings', 'requiredField'].includes(prop)
})`
  align-items: center;
  background: ${th('transparent.base')};
  border: 1px solid ${th('primary.base')};
  border-radius: 3px;
  color: ${th('text.dark')};
  display: ${props => (props.canHaveSiblings ? 'inline-flex' : 'flex')};
  flex-direction: row;
  font-size: 13px;
  height: ${props => (props.canHaveSiblings ? '35px' : props.requiredField ? '42px' : '40px')};
  justify-content: space-between;
  margin-bottom: 0;
  margin-right: 0;
  padding: ${spacing.tiny}px ${spacing.small}px;
`

export const OuterWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['canHaveSiblings', 'requiredField'].includes(prop)
})`
  display: block;
  margin-bottom: ${props => (props.canHaveSiblings ? '0' : '-40px')};
  margin-right: ${props => (props.canHaveSiblings ? '10px' : '0')};
  margin-top: ${props => (props.canHaveSiblings ? '10px' : '0')};
  position: relative;
  top: ${props => (props.canHaveSiblings ? '0' : props.requiredField ? '-42px' : '-40px')};
  width: 100%;

  @media screen and (min-width: 600px) {
    width: ${props => (props.canHaveSiblings ? 'max-content' : '500px')};
  }
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['canHaveSiblings'].includes(prop)
})`
  display: inline-block;
  left: 0;
  position: relative;
  width: ${props => (props.canHaveSiblings ? 'auto' : '100%')};
`

export const CloseIcon = styled(Close)`
  color: ${th('primary.dark')};
  cursor: pointer;
  margin-left: ${spacing.medium}px;
`
