import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// styles
import { Wrapper, Input } from './styles'
import { Error, Label, RequiredTag, Success, SubLabel } from '../InputLabels/InputLabels'

// assets
import { LoaderAlt } from '../Icons/Icons'
import { CheckCircle, XCircle } from 'components/Icons/Icons'

// utils
import th from 'utils/themeHelper'
import stringUtils from 'utils/string'

const InlineLink = styled(Link)`
  font-size: 12px;
  color: ${th('text.light')};
  padding: 0;
  margin: -2px 2px 6px;
  font-family: ${th('fonts.light')};
  line-height: 1.25em;
  text-decoration: underline;
`

const TextInputWrapper = styled(Wrapper)`
  display: ${props => (props.innerLabel ? 'block' : 'initial')};
  min-width: ${props => (props.forceFullWidthMobile || props.innerLabel ? '100%' : '500px')};
  position: relative;

  @media screen and (min-width: 769px) {
    min-width: ${props => (props.forceFullWidth ? '100%' : '500px')};
  }
`

const TextInputOuterWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['maxWidth'].includes(prop)
})`
  max-width: 100%;
  position: relative;

  @media screen and (min-width: 769px) {
    max-width: ${props => props.maxWidth || '100%'};
  }
`

const TextInputField = styled(Input).withConfig({
  shouldForwardProp: prop => !['hasError', 'requiredField', 'touched'].includes(prop)
})`
  padding: ${({ inputStyle, innerLabel }) => {
    if (inputStyle === 'username') return '15px 15px 15px 65px'
    if (innerLabel) return '0'
    return '0 15px'
  }};
`

const LoadingIconWrapper = styled.div`
  color: ${th('secondary.base')};
  position: absolute;
  height: 15px;
  right: 15px;
  top: 42px;
  z-index: 1;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const LoadingIconContainer = styled.div`
  height: 100%;
  width: 100%;
`

const Tab = styled.div`
  background: ${th('backgrounds.light')};
  bottom: ${props => (props.requiredField ? '2px' : '1px')};
  color: ${th('text.dark')};
  border-bottom-width: ${props => (props.requiredField ? '2px' : '1px')};
  border-top-width: ${props => (props.requiredField ? '2px' : '1px')};
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${th('borders.default')};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-family: ${th('fonts.regular')};
  font-size: 15px;
  height: ${props => (props.requiredField ? '41px' : '43px')};
  line-height: 43px;
  margin-left: ${props => (props.requiredField ? '2px' : '1px')};
  margin-top: ${props => (props.requiredField ? '2px' : '1px')};
  padding: 0;
  position: absolute;
  text-align: center;
  width: 50px;
`

const UsernameStatus = styled.span`
  display: inline-block;
  font-family: ${th('fonts.light')};
  font-size: 12px;
  line-height: 1.25em;
  margin: 0;
  padding: 0;
`

const UsernameStatusWrapper = styled.div`
  bottom: 15px;
  color: ${props =>
    props.error ? th('text.error') : props.valid ? th('text.dark') : th('text.light')};
  display: block;
  position: absolute;
  right: 15px;

  svg {
    color: ${props =>
      props.error ? th('text.error') : props.valid ? th('text.success') : th('text.light')};
    height: 15px;
    margin-right: 5px;
    stroke-width: 3px;
    width: 15px;
  }
`

export const UsernameWarning = ({ touched, values }) => {
  if (!values) return null
  if (!touched) return null

  const touchedFields = Object.keys(touched).length
  if (!touchedFields) return null

  const usernameEdited = touched['username'] || touched['bp_user_name']
  if (!usernameEdited) return null

  // bp_user_name sometimes used instead of username because Firefox autocomplete is annoying
  // and it sets it to true when the field has the word username
  const bpUsername = values.username || values.bp_user_name
  const { username_checked, username_pending, username_valid } = values

  if (!bpUsername) return null
  if (bpUsername.length < 3) return null

  if (username_pending) {
    return (
      <UsernameStatusWrapper>
        <UsernameStatus>Checking availability</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (username_valid) {
    return (
      <UsernameStatusWrapper valid>
        <CheckCircle />
        <UsernameStatus>Username available</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (username_checked) {
    return (
      <UsernameStatusWrapper error>
        <XCircle />
        <UsernameStatus>Username unavailable</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  return null
}

export const PboRegistrationNumberWarning = ({ fieldName, touched, values }) => {
  if (!values) return null
  if (!touched) return null
  if (fieldName !== 'pbo_registration_number') return null

  const touchedFields = Object.keys(touched).length
  if (!touchedFields) return null

  const pboRegNumberEdited = touched['pbo_registration_number']
  if (!pboRegNumberEdited) return null

  const pboRegNumber = values.pbo_registration_number
  const {
    pbo_registration_number_checked,
    pbo_registration_number_pending,
    pbo_registration_number_valid
  } = values

  if (!pboRegNumber) return null
  if (pboRegNumber.length < 8) return null

  if (pbo_registration_number_pending) {
    return (
      <UsernameStatusWrapper>
        <UsernameStatus>Checking availability</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (pbo_registration_number_valid) {
    return (
      <UsernameStatusWrapper valid>
        <CheckCircle />
        <UsernameStatus>PBO registration number available</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (pbo_registration_number_checked) {
    return (
      <UsernameStatusWrapper error>
        <XCircle />
        <UsernameStatus>PBO registration number unavailable</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  return null
}

export const NpoRegistrationNumberWarning = ({ fieldName, touched, values }) => {
  if (!values) return null
  if (!touched) return null
  if (fieldName !== 'npo_registration_number') return null

  const touchedFields = Object.keys(touched).length
  if (!touchedFields) return null

  const npoRegNumberEdited = touched['npo_registration_number']
  if (!npoRegNumberEdited) return null

  const npoRegNumber = values.npo_registration_number
  const {
    npo_registration_number_checked,
    npo_registration_number_pending,
    npo_registration_number_valid
  } = values

  if (!npoRegNumber) return null

  if (!npoRegNumber.toLowerCase().match(/\d{3}-\d{3}\s?npo/)) return true

  if (npo_registration_number_pending) {
    return (
      <UsernameStatusWrapper>
        <UsernameStatus>Checking availability</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (npo_registration_number_valid) {
    return (
      <UsernameStatusWrapper valid>
        <CheckCircle />
        <UsernameStatus>NPO registration number available</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  if (npo_registration_number_checked) {
    return (
      <UsernameStatusWrapper error>
        <XCircle />
        <UsernameStatus>NPO registration number unavailable</UsernameStatus>
      </UsernameStatusWrapper>
    )
  }

  return null
}

const LoadingIcon = ({ icon: Icon }) => {
  return (
    <LoadingIconWrapper>
      <LoadingIconContainer>
        <Icon
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        />
      </LoadingIconContainer>
    </LoadingIconWrapper>
  )
}

const getInputValue = ({ value, charLimit, wordLimit }) => {
  if (value && charLimit) {
    return value.slice(0, charLimit)
  }

  if (value && wordLimit) {
    const { splicedString } = stringUtils.trimText({
      string: value,
      wordLimit
    })

    return splicedString
  }

  return value
}

const SubLabelComponent = ({
  readMoreLabel,
  readMoreLink,
  subLabel,
  subLabelIsError,
  subLabelIsHtml
}) => {
  if (!subLabel) return null

  if (subLabelIsHtml) {
    return <SubLabel error={subLabelIsError} dangerouslySetInnerHTML={{ __html: subLabel }} />
  }

  if (!readMoreLink || !readMoreLabel) {
    return <SubLabel error={subLabelIsError}>{subLabel}</SubLabel>
  }

  return (
    <SubLabel error={subLabelIsError}>
      <span>{subLabel}</span>
      <InlineLink to={readMoreLink}>{readMoreLabel}</InlineLink>
    </SubLabel>
  )
}

const InputStyleArea = ({ inputStyle, requiredField }) => {
  if (inputStyle === 'username') {
    return <Tab requiredField={requiredField}>@</Tab>
  }

  return null
}

const LabelTag = ({
  disabled,
  field,
  focusRefId,
  handleFocus,
  hasError,
  innerLabel,
  keepLabelVisible,
  label,
  requiredField
}) => {
  const displayableLabel = disabled ? label : requiredField ? `${label} *` : label

  if (keepLabelVisible) {
    return (
      <Fragment>
        <Error innerLabel={innerLabel}>{hasError}</Error>
        <Label
          htmlFor={field.name}
          innerLabel={innerLabel}
          onClick={() => (focusRefId ? handleFocus(focusRefId) : null)}
        >
          {displayableLabel}
        </Label>
      </Fragment>
    )
  }

  if (hasError) {
    return <Error innerLabel={innerLabel}>{hasError}</Error>
  }

  if (label) {
    return (
      <Label
        htmlFor={field.name}
        innerLabel={innerLabel}
        onClick={() => (focusRefId ? handleFocus(focusRefId) : null)}
      >
        {displayableLabel}
      </Label>
    )
  }

  return null
}

const TextInput = ({
  charLimit,
  children,
  disabled,
  field,
  form: { errors, touched, ...formProps },
  forceFullWidth,
  forceFullWidthMobile,
  focusRefId,
  handleFocus,
  innerLabel,
  inputStyle,
  keepLabelVisible,
  label,
  maxWidth,
  readMoreLabel,
  readMoreLink,
  requiredField,
  subLabel,
  subLabelIsError,
  subLabelIsHtml,
  success,
  showLoadingIcon = false,
  values,
  wordLimit,
  ...props
}) => {
  const { value } = field
  const splicedString = getInputValue({ value, charLimit, wordLimit })

  const hasError = errors[field.name]

  return (
    <TextInputOuterWrapper inputStyle={inputStyle} maxWidth={maxWidth}>
      <TextInputWrapper
        innerLabel={innerLabel}
        forceFullWidth={forceFullWidth}
        forceFullWidthMobile={forceFullWidthMobile}
      >
        <LabelTag
          disabled={disabled}
          field={field}
          focusRefId={focusRefId}
          handleFocus={handleFocus}
          hasError={hasError}
          innerLabel={innerLabel}
          keepLabelVisible={keepLabelVisible}
          label={label}
          requiredField={requiredField}
        />

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />
        <SubLabelComponent
          readMoreLabel={readMoreLabel}
          readMoreLink={readMoreLink}
          subLabel={subLabel}
          subLabelIsError={subLabelIsError}
          subLabelIsHtml={subLabelIsHtml}
        />

        {children}

        <TextInputField
          // spread field then props then specify overrides
          {...field}
          {...props}
          disabled={disabled}
          forceFullWidth={forceFullWidth}
          hasError={!!hasError}
          innerLabel={innerLabel}
          inputStyle={inputStyle}
          onBlur={e => {
            if (formProps.handleBlur && props.validateOnBlur) {
              props.validateField({ value: e.target.value })
            }
          }}
          onChange={e => {
            if (!formProps.isValid && props.validateOnChange) {
              props.validateField({ value: e.target.value, forceClearErrors: true })
            }

            if (formProps.handleChange) {
              formProps.handleChange(e)
            }

            if (props.onChange) {
              props.onChange(e)
            }
          }}
          requiredField={requiredField}
          touched={!!touched[field.name]}
          value={splicedString}
        />

        {showLoadingIcon && <LoadingIcon icon={LoaderAlt} />}

        {success && touched[field.name] && !hasError && <Success>{success}</Success>}
      </TextInputWrapper>

      <InputStyleArea inputStyle={inputStyle} requiredField={requiredField} />
      <UsernameWarning touched={touched} values={values} />
      <PboRegistrationNumberWarning fieldName={field.name} touched={touched} values={values} />
      <NpoRegistrationNumberWarning fieldName={field.name} touched={touched} values={values} />
    </TextInputOuterWrapper>
  )
}

export default TextInput
