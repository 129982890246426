import React from 'react'
import { Outer, CloseIcon, Wrapper, OuterWrapper } from './styles'

const Tag = ({ handleClick, ...props }) => (
  <OuterWrapper {...props}>
    <Wrapper {...props}>
      <Outer requiredField={props.requiredField}>
        {props.children}
        {!props.immutable && <CloseIcon size={15} onClick={handleClick} />}
      </Outer>
    </Wrapper>
  </OuterWrapper>
)

export default Tag
