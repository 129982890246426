import React, { Component } from 'react'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import styled from 'styled-components'
import th from 'utils/themeHelper'
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'
import { Success, Label, Error, SubLabel } from '../InputLabels/InputLabels'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: ${props => props.maxWidth || '100%'};

  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    width: 100%;
  }
`

const DownIcon = styled(ArrowDropDown)`
  width: 30px;
  height: 30px;
  color: ${th('background.mid')};
`

const borderColorBottom = ({ menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.default
  } else if (hasError) {
    return theme.borders.error
  } else {
    return theme.borders.default
  }
}

const borderColor = ({ menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.success
  } else if (hasError) {
    return theme.borders.error
  } else {
    return theme.borders.default
  }
}

const StyledSelect = styled(Creatable)`
  .Select__control {
    border-style: solid;
    border-width: 1px;
    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    border-bottom-right-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    appearance: none;
    height: 45px;

    &:hover {
      border-color: ${borderColor};
      border-bottom-color: ${borderColorBottom};
    }
  }

  .Select__control.Select__control--is-focused {
    box-shadow: none;

    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};
    &:hover {
      border-color: ${borderColor};
      border-bottom-color: ${borderColorBottom};
    }
  }

  .Select__placeholder {
    color: ${th('text.light')};
  }

  .Select__single-value {
    color: ${th('text.dark')};
  }

  .Select__dropdown-indicator {
    color: ${th('background.mid')};
    &:focus,
    &:hover {
      color: ${th('background.mid')};
    }
  }

  .Select__menu {
    box-shadow: none;
    margin: 0;
    border-style: solid;
    border-width: 1px;

    border-color: ${th('secondary.base')};

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    border-top: none;
  }

  .Select__menu-list {
    padding: 0;
  }

  .Select__option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .Select__option--is-focused {
    background: ${th('secondary.lightest')};
  }

  .Select__multi-value {
    height: 35px;
    color: red;
  }

  .Select__multi-value__label {
    display: flex;
    align-items: center;
    color: ${th('primary.dark')};
    background: ${th('primary.lightest')};
    border: 1px solid ${th('primary.dark')};
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Select__multi-value__remove {
    color: ${th('primary.dark')};
    background: ${th('primary.lightest')};
    border: 1px solid ${th('primary.dark')};
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      color: ${th('primary.dark')};
      background: ${th('primary.lightest')};
    }
  }

  .Select__clear-indicator {
    margin-right: 10px;
    &:after {
      margin-top: 4px;
      content: 'Remove';
      font-size: 13px;
    }
  }
`
const IndicatorSeparator = () => {
  return null
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownIcon />
      </components.DropdownIndicator>
    )
  )
}

const valueFromId = (opts, id) => {
  return opts.find(o => o.value === id)
}

class MySelect extends Component {
  // Needed for correct styling
  state = {
    menuOpen: false,
    createdOptions: []
  }

  // Integrate with Formik
  onChange = option => {
    // do nothing for a disabled option
    if (option.disabled) return true

    const { field, form } = this.props

    form.setFieldValue(field.name, option ? option.value : null)
    form.setFieldValue('hasNewValue', false)
  }

  handleCreate = newGroupName => {
    const { createdOptions } = this.state
    this.setState({
      createdOptions: [].concat(...createdOptions, { label: newGroupName, value: newGroupName })
    })
    this.props.handleNewAddition(newGroupName)
  }

  render() {
    const { menuOpen, createdOptions } = this.state
    const {
      field = {},
      form: { errors = {}, touched = {} } = {},
      label,
      maxWidth,
      subLabel,
      success,
      className,
      ...props
    } = this.props

    const allOpts = [].concat([...this.props.options, ...createdOptions])

    return (
      <Wrapper className={className} maxWidth={maxWidth}>
        {label && <Label htmlFor={field.name}>{label}</Label>}
        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <StyledSelect
          classNamePrefix="Select"
          components={{
            IndicatorSeparator,
            DropdownIndicator
          }}
          hasError={!!errors[field.name]}
          isDisabled={props.disabled}
          menuOpen={menuOpen}
          {...field}
          touched={!!touched[field.name]}
          onMenuOpen={() => this.setState({ menuOpen: true })}
          onMenuClose={() => this.setState({ menuOpen: false })}
          onChange={this.onChange}
          hideSelectedOptions={true}
          onCreateOption={this.handleCreate}
          value={valueFromId(allOpts, field.value)}
          options={allOpts}
          {...props}
        />

        {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
        {errors[field.name] && <Error>{errors[field.name]}</Error>}
      </Wrapper>
    )
  }
}

export default MySelect
