import styled, { css } from 'styled-components'
import th from 'utils/themeHelper'

const textColor = 'white'
const backgroundColor = '#000000CC'

export const TooltipWrapper = styled.div`
  align-items: center;
  height: ${props => props.height || '100%'};
  display: ${props => (props.displayFlex ? 'flex' : props.displayBlock ? 'block' : 'inline-block')};
  position: relative;

  > p {
    margin: 0;
  }

  @media screen and (min-width: 769px) {
    display: ${props => (props.displayFlex ? 'flex' : props.displayBlock ? 'block' : 'unset')};
  }

  &:hover {
    cursor: ${props => props.cursor || 'help'};
  }
`

export const TooltipTip = styled.div.withConfig({
  shouldForwardProp: prop => !['minWidth', 'tinyTooltip', 'isWarning', 'translateY'].includes(prop)
})`
  background: ${props => (props.isWarning ? th('backgrounds.dark') : backgroundColor)};
  border-radius: 4px;
  color: ${textColor};
  font-family: sans-serif;
  font-size: ${props => (props.tinyTooltip ? '12px' : '15px')};
  left: 0;
  line-height: 1.35em;
  min-width: ${props => (props.minWidth ? 'max-content' : '300px')};
  padding: ${props => (props.tinyTooltip ? '5px' : '10px')};
  position: absolute;
  z-index: 100;

  @media screen and (min-width: 769px) {
    ${({ position, tinyTooltip }) => {
      switch (position) {
        case 'top':
          return css`
            top: -10px;
            transform: translateX(-15%) translateY(-100%);

            &:before {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid
                ${props => (props.isWarning ? th('backgrounds.dark') : backgroundColor)};
              content: '';
              display: block;
              height: 0;
              position: absolute;
              top: 100%;
              left: 25%;
              width: 0;
            }
          `
        case 'right':
          return css`
            bottom: 0;
            left: calc(100% + 20px);
            right: -10px;
            transform: translateX(0) translateY(${tinyTooltip ? '-35%' : '35%'});

            &:before {
              border-bottom: 5px solid transparent;
              border-right: 5px solid
                ${props => (props.isWarning ? th('backgrounds.dark') : backgroundColor)};
              border-top: 5px solid transparent;
              content: '';
              display: block;
              height: 0;
              position: absolute;
              right: 100%;
              top: 35%;
              width: 0;
            }
          `
        case 'bottom':
          return css`
            transform: translateX(-50%);

            &:before {
              border-bottom: 5px solid
                ${props => (props.isWarning ? th('backgrounds.dark') : backgroundColor)};
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              content: '';
              display: block;
              height: 0;
              position: absolute;
              bottom: 100%;
              left: 50%;
              width: 0;
            }
          `
        case 'left':
          return css`
            bottom: 0;
            left: -10px;
            right: calc(100% + 20px);
            transform: translateX(-100%) translateY(${props => props.translateY || '35'}%);

            &:before {
              border-bottom: 5px solid transparent;
              border-left: 5px solid
                ${props => (props.isWarning ? th('backgrounds.dark') : backgroundColor)};
              border-top: 5px solid transparent;
              content: '';
              display: block;
              height: 0;
              left: 100%;
              position: absolute;
              top: 35%;
              width: 0;
            }
          `
        default:
          break
      }
    }}
  }

  @media screen and (max-width: 769px) {
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 80px;
    width: 96%;
  }
`
