import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// styles
import { Error, Label, RequiredTag, SubLabel, Success } from '../InputLabels/InputLabels'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DecrementButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 45px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;

  /* Prevent blue highlighting of page numbers from multiple clicking in browsers */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const IncrementButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 45px;
  justify-content: center;
  right: 0;
  position: absolute;
  top: 0;
  width: 45px;

  /* Prevent blue highlighting of page numbers from multiple clicking in browsers */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Input = styled.input.withConfig({
  shouldForwardProp: prop =>
    ![
      'hasError',
      'hideArrows',
      'maxWidth',
      'requiredField',
      'shortNumberField',
      'touched'
    ].includes(prop)
})`
  appearance: none;
  outline: none;
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ requiredField, hasError, theme }) =>
    hasError
      ? theme.borders.error
      : requiredField
      ? theme.borders.required
      : theme.borders.default};
  border-width: ${({ requiredField, hasError }) =>
    hasError ? '2px' : requiredField ? '2px' : '1px'};
  color: ${th('text.dark')};
  background: ${({ disabled, theme }) => (disabled ? theme.backgrounds.mid : 'white')};
  font-size: 15px;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  text-align: ${props => (props.hideArrows ? 'center' : 'left')};

  -moz-appearance: ${props => (props.hideArrows ? 'textfield' : 'auto')};

  // input::-webkit-outer-spin-button,
  // input::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }

  @media screen and (min-width: 600px) {
    min-width: ${props => (props.shortNumberField ? '150px' : '500px')};
    max-width: ${props => (props.shortNumberField ? '150px' : '500px')};
  }

  &:hover {
    border-color: ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.borders.dark)};
  }

  &:focus {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.borders.error : theme.secondary.base};
  }

  &::placeholder {
    color: ${th('text.light')};
    font-family: ${th('fonts.light')};
  }
`

const handleKeyDown = e => {
  // Allow numbers, backspace, delete, tab, escape, enter, and period
  const isControlKey = e.ctrlKey || e.metaKey
  const isShortcutKey = isControlKey && ['a', 'c', 'v', 'x'].includes(e.key.toLowerCase())
  const isValidKey = /[0-9]|Backspace|Delete|Tab|Escape|Enter|\./.test(e.key) || isShortcutKey

  if (!isValidKey) {
    e.preventDefault()
  }
}

const handleOnChange = ({ e, field, setFieldValue }) => {
  const { value: inputValue } = e.target

  // This regular expression allows optional negative sign, digits, optionally a period, and up to two digits after the period
  // Remove undesired characters immediately
  const sanitizedValue = inputValue.replace(/[acvx]/g, '')
  const isValid = /^-?\d*(\.\d{0,2})?$/.test(sanitizedValue)

  if (isValid || sanitizedValue === '') {
    setFieldValue(field.name, sanitizedValue)
  }
  // No else needed; if not valid, the sanitizedValue won't include invalid characters anyway
}

const NumberInput = ({
  changeValue,
  children,
  field,
  form: { errors, setFieldValue, touched },
  min,
  max,
  label,
  requiredField,
  shortNumberField,
  step,
  subLabel,
  success,
  withButtons,
  ...props
}) => {
  const displayableLabel = requiredField ? `${label} *` : label
  const hasError = !!errors[field.name]

  if (withButtons) {
    return (
      <Wrapper>
        {label && <Label htmlFor={field.name}>{displayableLabel}</Label>}
        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        {children}

        <div style={{ position: 'relative', width: '150px' }}>
          <DecrementButton onClick={() => changeValue({ value: -1 })}>-</DecrementButton>

          <Input
            {...field}
            {...props}
            hideArrows
            requiredField={requiredField}
            shortNumberField
            type="number"
            min={min || '0'}
            max={max || undefined}
            onChange={e => handleOnChange({ e, field, setFieldValue })}
            onKeyDown={handleKeyDown}
            onWheel={event => event.currentTarget.blur()}
            step={step || '.01'}
            touched={!!touched[field.name]}
            hasError={hasError}
          />
          <IncrementButton onClick={() => changeValue({ value: 1 })}>+</IncrementButton>
        </div>

        {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
        {errors[field.name] && <Error>{errors[field.name]}</Error>}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {label && <Label htmlFor={field.name}>{displayableLabel}</Label>}
      {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

      <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

      {children}

      <Input
        {...field}
        {...props}
        requiredField={requiredField}
        shortNumberField={shortNumberField}
        type="number"
        onChange={e => handleOnChange({ e, field, setFieldValue })}
        onKeyDown={handleKeyDown}
        min={min || '0'}
        max={max || undefined}
        onWheel={event => event.currentTarget.blur()}
        step={step || '.01'}
        touched={!!touched[field.name]}
        hasError={hasError}
      />

      {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
      {errors[field.name] && <Error>{errors[field.name]}</Error>}
    </Wrapper>
  )
}

export default NumberInput
