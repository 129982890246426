import React from 'react'
import styled from 'styled-components'
import th from 'utils/themeHelper'

export const Error = styled.div`
  font-size: 13px;
  color: ${th('text.error')};
  padding: ${props => (props.innerLabel ? '0 2px 2px 0' : '2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
  font-family: ${th('fonts.bold')};
  line-height: 1.25em;
  width: max-content;
`

export const CheckboxLabel = styled.div`
  color: ${props => (props.disabled ? th('text.light') : th('text.dark'))};
  font-size: 13px;
  line-height: 1.25em;
  margin-bottom: 0;
  padding: 5px;
  position: ${props => (props.floatLeft ? 'absolute' : 'relative')};
`

export const Label = styled.div`
  font-size: 13px;
  color: ${props => (props.disabled ? th('text.light') : th('backgrounds.dark'))};
  padding: ${props => (props.innerLabel ? '0 2px 2px 0' : '0 2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '2px')};
  font-family: ${th('fonts.bold')};
  position: relative;
  line-height: 1.25em;
  width: max-content;

  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
`

const RequiredTagPill = styled.div.withConfig({
  shouldForwardProp: prop => !['hasError'].includes(prop)
})`
  border: 1px solid ${props => (props.hasError ? th('text.error') : th('text.default'))};
  border-radius: 3px;
  color: ${props => (props.hasError ? th('text.error') : th('text.default'))};
  font-family: ${props => (props.hasError ? th('fonts.bold') : th('fonts.regular'))};
  font-size: 8px;
  left: -23px;
  line-height: 8px;
  padding: 1px 2px;
  position: absolute;
  text-transform: uppercase;
  top: 4px;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const RequiredTag = ({ hasError, label, requiredField }) => {
  const canDisplayFeature = false

  if (!label || !requiredField || !canDisplayFeature) return null

  return <RequiredTagPill hasError={hasError}>req</RequiredTagPill>
}

export const SubLabel = styled.div.withConfig({
  shouldForwardProp: prop => !['subLabel'].includes(prop)
})`
  font-size: 12px;
  color: ${props => (props.error ? th('text.error') : th('text.light'))};
  padding: 0;
  margin: -2px 2px 6px;
  font-family: ${th('fonts.light')};
  max-width: 100%;
  line-height: 1.25em;
  width: 100%;

  a {
    color: ${th('text.light')};
    font-size: 12px;
    text-decoration: underline;
  }

  @media screen and (min-width: 1024px) {
    max-width: 700px;
  }
`

export const SubLabelStrong = styled(SubLabel)`
  color: ${th('text.mid')};
  font-family: ${th('fonts.bold')};
  margin: 0;
  position: absolute;
  right: 10px;
  top: 0;
  width: auto;
`

export const Success = styled.div`
  font-size: 13px;
  color: ${th('text.dark')};
  padding: ${props => (props.innerLabel ? '0 2px 2px 2px' : '2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
  font-family: ${th('fonts.bold')};
  line-height: 1.25em;
`

export default { Error, Label, RequiredTag, Success, SubLabel }
